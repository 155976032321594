<template>
    <div class="card">
        <div class="card__header">
            <img class="card__image" @click="$emit('open-image', state.image)" :src="state.image_thumbnail"/>
            <div class="card__title-container" v-if="state.description">
                <h2 class="card__title">{{ state.name }}</h2>
                <h2 class="card__title--description">{{ state.description }}</h2>
            </div>
            <div class="card__title-container" v-else>
                <h2 class="card__title--large">{{ state.name }}</h2>
            </div>

        </div>
        <div class="card__body">
            <div class="card__price-line">
                <p class="card__price-info" v-if="state.consumer_price">{{ $t('consumerPriceBouquet') }}</p>
                <p class="card__price-price mb-2" v-if="state.consumer_price">€ {{ state.consumer_price }}</p>
                <p class="card__price-info">{{ $t('purchasePriceBouquet') }}</p>
                <p class="card__price-price">€ {{ state.price }}</p>
            </div>
            <div class="card__information-container">
                <div class="card__information-line">
                    <p class="card__information-label">{{ $t('category') }}</p>
                    <p class="card__information-text" v-if="product.category">{{ product.category }}</p>
                    <p class="card__information-text" v-else>-</p>
                </div>
                <div class="card__information-line">
                    <p class="card__information-label">{{ $t('height') }}</p>
                    <p class="card__information-text" v-if="product.height">{{ product.height }}CM</p>
                    <p class="card__information-text" v-else>-</p>
                </div>
                <div class="card__information-line--full">
                    <div class="card__information-line">
                        <p class="card__information-label">{{ $t('loadCarrier') }}</p>
                        <p class="card__information-text"
                           v-if="product.load_carrier && product.load_carrier_layer_amount">{{
                                product.load_carrier
                            }}</p>
                        <p class="card__information-text" v-else>-</p>
                    </div>
                    <div class="card__information-line">
                        <p class="card__information-label">{{ $t('loading') }}</p>
                        <p class="card__information-text"
                           v-if="product.load_carrier && product.load_carrier_layer_amount">
                            {{ product.load_carrier_layer_amount }} x {{ product.crates_per_layer }} x {{ product.amount_per_packaging }}</p>
                        <p class="card__information-text" v-else>-</p>
                    </div>
                </div>
                <div class="card__information-line--full">
                    <div class="card__information-line">
                        <p class="card__information-label">{{ $t('packaging') }}</p>
                        <p class="card__information-text"
                           v-if="product.packaging_type">{{ product.packaging_type }}</p>
                        <p class="card__information-text" v-else>-</p>
                    </div>
                    <div class="card__information-line">
                        <p class="card__information-label">{{ $t('ean') }}</p>
                        <p class="card__information-text"
                           v-if="product.ean">{{ product.ean }}</p>
                        <p class="card__information-text" v-else>-</p>
                    </div>
                </div>
                <div class="card__information-line--full">
                    <div class="card__information-line">
                        <p class="card__information-label">{{ $t('orderUnit') }}</p>
                        <p class="card__information-text" v-if="product.amount_per_packaging">
                            {{ product.amount_per_packaging }}</p>
                        <p class="card__information-text" v-else>-</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="card__actions">
            <div class="card__actions-amount" :class="inCart ? 'added' : ''">
                <template v-if="!inCart">
                    <button class="button button--primary" v-if="amount > state.minimal_amount" @click="subOne">-
                    </button>
                    <button class="button button--primary"
                            style="visibility: hidden; cursor: default; touch-action: none; pointer-events: none;"
                            v-else></button>
<!--                    <div class="input-holder" data-suffix="x 5">-->
                    <input type="number" v-model="amount"/>
<!--                    </div>-->
                    <button class="button button--primary" @click="addOne">+</button>
                </template>
                <template v-else>
                    <span class="amount-ordered">{{ amount }} x {{ product.amount_per_packaging }}</span>
                </template>
            </div>
            <div class="card__actions-confirm">
                <button class="button button--success" @click="toggleOrdered">
                    {{ inCart ? $t('remove') : $t('order') }}
                </button>
            </div>
        </div>
        <div class="card__order-amount">
           {{ amount }} x {{ state.amount_per_packaging }} = {{ amount * state.amount_per_packaging }} {{ $t('pieces') }}
        </div>
    </div>
</template>

<script>
import {reactive, ref, watch} from 'vue';

export default {
    name: "ProductCard",
    props: {
        product: Object,
        locationId: String,
        onEditSupply: Function,
        locationConfirmed: Boolean,
        inCart: Boolean,
    },
    setup(props, context) {
        const state = reactive({
            name: props.product.name,
            description: props.product.product_description,
            availability_id: props.product.availability.id,
            id: props.product.id,
            image: props.product.image,
            image_thumbnail: props.product.image_thumbnail,
            price: props.product.price,
            consumer_price: props.product.consumer_price,
            minimal_amount: props.product.minimal_amount,
            amount_per_packaging: props.product.amount_per_packaging,
            height: props.product.height,
            load_carrier: props.product.load_carrier,
            load_carrier_layer_amount: props.product.load_carrier_layer_amount,
            crate: props.product.crate,
            crates_per_layer: props.product.crates_per_layer,
            ean: props.product.ean,
            packaging_type: props.product.packaging_type,
        });

        const amount = ref(props.product.minimal_amount)



        const toggleOrdered = () => {
            context.emit('toggleOrdered', {
                id: state.id,
                amount: amount.value,
                amount_per_packaging: state.amount_per_packaging,
                minimal_amount: state.minimal_amount,
                price_per_packaging: state.price,
                name: state.name,
                price: (amount.value * state.amount_per_packaging) * state.price,
                availability_id: state.availability_id
            })
        }

        const addOne = () => {
            amount.value++
        }

        const subOne = () => {
            amount.value--
        }

        watch(amount, () => {
            if (amount.value < state.minimal_amount) {
                amount.value = state.minimal_amount
            }
        })

        return {
            state,
            amount,
            addOne,
            subOne,
            toggleOrdered
        };
    },
    components: {},
}
</script>

<style lang="scss" scoped>
.card {
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
    display: flex;
    flex-direction: column;
    background-color: $white;
    justify-content: space-between;
    z-index: 2;

    &__header {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        justify-content: flex-end;
        align-items: flex-end;

        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 50%;
            position: absolute;
            bottom: 0;
            left: 0;
            background: linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
            z-index: 1;
            transition: all 0.1s ease-in-out;
        }

        &:hover {
            cursor: pointer;
            &::after {
                background: linear-gradient(to top, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
                height: 10%;
            }

            .card__title-container {
                transition: all 0.15s ease-in-out;
                opacity: 0;
                display: none;
            }
        }
    }

    &__image {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
        z-index: 0;
        width: 100%;
        aspect-ratio: 4/4;
        object-fit: cover;
    }

    &__title-container {
        color: $white;
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
        padding: 2rem;
        z-index: 2;
    }

    &__title {
        font-size: 1.4rem;
        padding-bottom: .4rem;

        &--description {
            font-size: 2rem;
        }

        &--large {
            font-size: 2rem;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        padding: 1.6rem;
        height: 100%;
    }

    &__product-category {
        font-weight: 700;
    }

    &__product-stock {
        font-weight: 300;
    }

    &__product-label {
        display: inline-block;
        padding: 0.50em 0.8em;
        font-size: 100%;
        font-weight: 700;
        line-height: 1;
        text-align: center;
        color: white;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 0.25rem;
        background-color: #7e90c2;
        margin-left: 0.50em;
    }

    &__product-label-red {
        display: inline-block;
        padding: 0.50em 0.8em;
        font-size: 100%;
        font-weight: 700;
        line-height: 1;
        text-align: center;
        color: white;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 0.25rem;
        background-color: red;
        margin-left: 0.50em;
    }

    &__status-icon {
        border-radius: 50%;
        width: 1.2rem;
        height: 1.2rem;
        margin-right: 0.5rem;
        display: inline-block;

        &.green {
            background-color: $succes;
        }

        &.red {
            background-color: $danger;
        }
    }

    &__price {
        &-line {
            display: flex;
            flex-direction: column;
        }

        &-info {
            font-size: 1.4rem;
            font-weight: 200;
        }

        &-price {
            font-size: 1.4rem;
        }
    }

    &__information {
        &-label {
            font-size: 1.4rem;
            font-weight: 200;
        }

        &-text {
            font-size: 1.4rem;
        }

        &-line {
            width: 50%;
            margin-top: 1.6rem;
            display: flex;
            flex-direction: column;

            &--full {
                display: flex;
                width: 100%;
            }
        }

        &-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    &__actions {
        background-color: #f9f9f9;
        padding: 1.6rem;
        width: 100%;
        display: flex;
        justify-content: space-between;

        &-confirm {
            width: calc(50% - .5rem);

            button {
                width: 100%;
                height: 3rem;
                padding: 0;
                font-size: 1rem;
            }
        }

        &-amount {
            display: flex;
            width: calc(50% - .5rem);
            justify-content: space-between;

            &.added {
                justify-content: center;
                align-items: center;
            }

            &.disabled {
                display: none;
            }

            button {
                width: 3rem;
                height: 3rem;
                padding: 0;
            }

            input {
                max-width: 50%;
                border-radius: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $primary;
                color: $white;
                font-size: 1rem;
                cursor: pointer;
                text-align: center;
                margin: 0 3px;
            }

            .input-holder {

            }
        }
    }

    &__order-amount {
        display: flex;
        justify-content: center;
        padding: .8rem;
        color: #000000;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.4;
    }
}

input[type=number] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.row-space {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.row-space-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

</style>
