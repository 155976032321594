<template>
    <page-title>
        {{$t('order')}}
    </page-title>
    <div class="sub-title wrapper" v-if="state.products && state.products.length !== 0">
        <span>{{ $t('productsInWeek', { weekNumber: weekNumber, productCount: state.products.length }) }}</span>
        <span v-if="state.alreadyOrdered">{{ $t('alreadyOrderedToday') }}</span>
    </div>
    <div class="filter__container filter--sticky">
        <div class="filter wrapper">
            <div>
                <label for="week-filter" data-v-238b419e="">{{ $t('week') }}</label>
                <select name="search" v-model="weekNumber" class="form__select" id="week-filter">
                    <option v-for="week in state.possibleWeeks" :value="week">{{ week }}</option>
                </select>
            </div>
            <div>
                <label for="sort-filter">{{ $t('sort') }}</label>
                <select name="sort" v-model="state.sort" class="form__select form__select--sort" id="sort-filter">
                    <option value="price_down">{{ $t('priceHighToLow') }}</option>
                    <option value="price_up">{{ $t('priceLowToHigh') }}</option>
                    <option value="product_name">{{ $t('productName') }}</option>
                    <option value="ean">{{ $t('ean') }}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="products" style="position: relative" v-if="state.products && state.products.length !== 0">

        <div class="products__container wrapper">
            <product-card @open-image="showImg" @toggle-ordered="toggleOrdered" :in-cart="inCart(product)"
                v-for="product in state.products" :key="product.availability_id" :product="product"></product-card>
        </div>
        <img src="@assets/images/hoorn-animation-202-cutoff.webp" alt="footer__image" class="footer_image">
    </div>
    <div class="" style="position: relative" v-else>
        <div class="wrapper">
            <p>{{ $t('determiningAssortment') }}</p>
            <img src="@assets/images/hoorn-animation-202-cutoff.webp" alt="footer__image"
                class="footer_image footer_image--empty-week">
        </div>
    </div>
    <div class="confirmation-container" v-if="!state.modalOpen && state.orderList.length > 0 && state.orderCount >= 1">
        <div v-if="!state.orderVerified" style="display: flex; align-items: center;">
            <button class="button button--primary" @click="toggleModal">
                {{ $t('confirmOrder') }}
            </button>
            <p v-if="state.orderList.length > 0" class="ml-4">
                {{ $t('productUnits', {productAmount : state.orderList.length, unitCount: state.orderCount}) }}
            </p>
        </div>
        <p class="mt-4" v-else>
            {{ $t('orderConfirmed') }}
        </p>
    </div>
    <Modal v-if="state.modalOpen" :onClose="toggleModal" :modalVisible="state.modalOpen">
        <ProductOrderForm :orderConfirmed="state.orderConfirmed" @confirm-order="storeOrder" :orders="state.orderList"
            :weekNumber="weekNumber" :placedOrderDates="state.placedOrderDates" @remove-order="toggleOrdered">
        </ProductOrderForm>
    </Modal>
    <vue-easy-lightbox :visible="visibleRef" :imgs="lightboxRef" @hide="onHide"></vue-easy-lightbox>
</template>

<script>
import { reactive, watch, ref, onMounted } from 'vue';
import PageTitle from '@components/ui/PageTitle.vue';
import apiClient from '@services/apiClient'
import ProductCard from "../components/product/ProductCard";
import Modal from "@components/ui/Modal";
import ProductOrderForm from "@components/product/ProductOrderForm";

export default {
    name: "Florist",
    setup() {
        const state = reactive({
            modalOpen: false,
            products: [],
            orderList: [],
            orderVerified: false,
            alreadyOrdered: false,
            orderConfirmed: false,
            possibleWeeks: [],
            orderCount: 0,
            sort: 'price_up',
            placedOrderDates: [],
        });



        const weekNumber = ref(null)

        watch(weekNumber, (newValue) => {
            state.orderList = []
            fetchProducts(newValue)
        })

        watch(() => state.sort, (newValue) => {
            console.log(newValue)
            sortProducts();
        });

        const sortProducts = () => {
            if (state.sort) {
                switch (state.sort) {
                    case 'price_down':
                        state.products.sort((a, b) => b.price - a.price);
                        break;
                    case 'price_up':
                        state.products.sort((a, b) => a.price - b.price);
                        break;
                    case 'product_name':
                        state.products.sort((a, b) => a.name.localeCompare(b.name));
                        break;
                    case 'ean':
                        state.products.sort((a, b) => a.ean.localeCompare(b.ean));
                        break;
                    default:
                        break;
                }
            }
        };

        const toggleModal = () => {
            state.modalOpen = !state.modalOpen
        }

        const toggleOrdered = (product) => {

            let index = state.orderList.findIndex((p) => p.id === product.id)
            if (index < 0) {
                state.orderList.push(product)

                let order_total = 0;
                state.orderList.forEach(val => {
                    order_total += Number(val.amount);
                    //or if you pass float numbers , use parseFloat()
                });
                state.orderCount = order_total;
            } else {
                state.orderList.splice(index, 1)
                let order_total = 0;
                state.orderList.forEach(val => {
                    order_total += Number(val.amount);
                    //or if you pass float numbers , use parseFloat()
                });
                state.orderCount = order_total;
            }
        }

        const fetchProducts = (week = null) => {
            let baseUrl = '/product'
            week ? baseUrl += `?week=${week}` : null
            apiClient.get(baseUrl)
                .then(response => {
                    state.products = response.data.products
                    state.alreadyOrdered = response.data.already_ordered
                    state.placedOrderDates = response.data.placed_orders
                    if (!week) {
                        weekNumber.value = response.data.week_number
                    }
                    state.possibleWeeks = response.data.possible_weeks
                    sortProducts(); // Sort the products after fetching them
                })
                .catch(error => console.log(error));
        }

        const storeOrder = (date) => {
            apiClient.post('order', {
                products: state.orderList,
                desired_delivery_date: date,
                plan_week: weekNumber.value
            }).then((resp) => {
                if (resp.status === 200) {
                    state.orderVerified = true
                    state.orderConfirmed = true
                }
            })
        }

        const inCart = (product) => {
            let index = state.orderList.findIndex((p) => p.id === product.id)
            if (index < 0) {
                return false
            }
            return true
        }

        const closeModal = () => {
            state.modalOpen = false
        }

        const visibleRef = ref(false)
        const lightboxRef = ref('')
        const showImg = (img) => {
            lightboxRef.value = img
            visibleRef.value = true
        }
        const onHide = () => visibleRef.value = false

        onMounted(() => {
            fetchProducts();
        });

        return {
            state,
            sortProducts,
            fetchProducts,
            toggleOrdered,
            storeOrder,
            toggleModal,
            weekNumber,
            visibleRef,
            showImg,
            onHide,
            inCart,
            lightboxRef
        };
    },

    components: {
        ProductOrderForm,
        Modal,
        ProductCard,
        PageTitle,
    },


}
</script>

<style lang="scss" scoped>
.confirmation-container {
    width: 100%;
    padding: .5rem 0;
    height: 5rem;
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    background-color: $light-grey;
    z-index: 999;

    @media only screen and (max-width: 480px) {
        p {
            display: none;
        }
    }
}

.products {
    background-color: $light-grey;

    &__container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr));
        gap: 4.5rem;
        padding-top: 4rem;
        padding-bottom: 6rem;
    }
}

.sub-title {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
}

.row {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.recover-button {
    max-height: 1px !important;
    margin-left: 2rem;
    margin-bottom: 2rem;
    padding: 2rem;
}

.footer_image {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40%;
    opacity: 0.5;
    z-index: 1;

    &--empty-week {
        position: fixed;
        width: 30%;
    }

    @media only screen and (max-width: 699px) {
        width: 80%;
    }

    @media only screen and (max-width: 899px) {
        width: 60%;
    }
}
</style>
