import { createWebHistory, createRouter } from "vue-router";
import Florist from "@pages/Florist.vue";

const routes = [
  {
    path: "/",
    name: "Florist",
    component: Florist,
  },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
