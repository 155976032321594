import axios from 'axios'

const apiClient = axios.create({
    baseURL: process.env.MIX_API_URL,
    headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('api-token')}`
    }
})

const errorEvent = new Event('errorResponse')
const successEvent = new Event('successResponse')

apiClient.interceptors.response.use(function (response) {

    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    window.dispatchEvent(successEvent)
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status >= 500) {
        window.dispatchEvent(errorEvent)
    }
    return Promise.reject(error);
});

export default apiClient
