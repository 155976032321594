<template>
  <teleport to="#modal-location" v-if="state.isOpen">
    <div class="modal">
      <div class="modal__background" v-on:click="close"></div>
      <div class="modal__content">
        <div class="modal__close" v-on:click="close">
          <font-awesome-icon class="modal__close-mark" icon="fa-solid fa-xmark" />
        </div>
        <slot></slot>
      </div>
    </div>
  </teleport>
</template>

<script>
import { reactive } from 'vue';

export default {
  name: "Modal",
  props: {
    modalVisible: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const state = reactive({
      isOpen: props.modalVisible
    });

    const close = () => {
      props.onClose();
    };

    return {
      state,
      close
    }
  },
}
</script>

<style lang="scss" scoped>
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;

    &__background{
      background-color: rgba(0, 0, 0, 0.75);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    &__content {
      position: relative;
      background-color: $white;
      padding: 6rem 4rem 4rem;
      border-radius: .5rem;
      width: 50rem;
      max-width: 90vw;
    }

    &__close {
      position: absolute;
      top: 1rem;
      right: 1rem;
      width: 3rem;
      height: 3rem;
      background-color: $black;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      padding-left: 1px;
    }

    &__close-mark {
      color: $white;
      font-size: 2rem;
    }
  }
</style>
