<template>
    <p>{{ $t('orderingForWeek', {week: weekNumber}) }}</p>
    <table>
        <!-- ... Table headers ... -->
        <tr :class="{ 'order-editing': order.editing }" v-for="order in orders" :key="order.id">
            <td>{{ order.name }}</td>
            <td v-if="!order.editing">
                {{ order.amount }} x {{ order.amount_per_packaging }} =
                {{ order.amount * order.amount_per_packaging }}
            </td>
            <td v-else>
                <div class="edit-section">
                    <button v-if="order.amount > order.minimal_amount" class="edit-button"
                        @click="decrementOrderAmount(order)">-</button>

                    <input class="edit-input" type="number" v-model="order.amount" @input="adjustOrderAmount(order)" />
                    <button class="edit-button" @click="incrementOrderAmount(order)">+</button>
                </div>
            </td>
            <td>€ {{ order.price.toFixed(2) }}</td>
            <td v-if="!props.orderConfirmed">
                <span class="edit-controls">
                    <button class="edit-icon-button" @click="toggleEdit(order)">
                        <svg v-if="order.editing" class="edit-icon" viewBox="0 0 448 512">
                            <path
                                d="M48 96V416c0 8.8 7.2 16 16 16H384c8.8 0 16-7.2 16-16V170.5c0-4.2-1.7-8.3-4.7-11.3l33.9-33.9c12 12 18.7 28.3 18.7 45.3V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96C0 60.7 28.7 32 64 32H309.5c17 0 33.3 6.7 45.3 18.7l74.5 74.5-33.9 33.9L320.8 84.7c-.3-.3-.5-.5-.8-.8V184c0 13.3-10.7 24-24 24H104c-13.3 0-24-10.7-24-24V80H64c-8.8 0-16 7.2-16 16z" />
                        </svg>
                        <svg v-else class="edit-icon" viewBox="0 0 512 512">
                            <path
                                d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z" />
                        </svg>
                    </button>
                    <button v-if="!order.editing" class="edit-icon-button" @click="$emit('remove-order', order)">
                        <svg class="edit-icon" viewBox="0 0 448 512">
                            <path
                                d="M177.1 48h93.7c2.7 0 5.2 1.3 6.7 3.6l19 28.4h-145l19-28.4c1.5-2.2 4-3.6 6.7-3.6zM354.2 80L317.5 24.9C307.1 9.4 289.6 0 270.9 0H177.1c-18.7 0-36.2 9.4-46.6 24.9L93.8 80H80.1 32 24C10.7 80 0 90.7 0 104s10.7 24 24 24H35.6L59.6 452.7c2.5 33.4 30.3 59.3 63.8 59.3H324.6c33.5 0 61.3-25.9 63.8-59.3L412.4 128H424c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8H367.9 354.2zm10.1 48L340.5 449.2c-.6 8.4-7.6 14.8-16 14.8H123.4c-8.4 0-15.3-6.5-16-14.8L83.7 128H364.3z" />
                        </svg>
                    </button>
                </span>
            </td>
        </tr>
        <tr>
            <td class="total-text">{{ $t('total') }}</td>
            <td class="total-text">{{ orderCount }}</td>
            <td class="total-text">€ {{ totalPrice.toFixed(2) }}</td>
        </tr>
    </table>
    <div style="font-size: 14px;" v-if="props.orderConfirmed">
        {{ $t('orderConfirmed') }}
    </div>
    <div v-else class="form">
        <div class="form__control-group mb-2">
            <p>
                {{ $t('deliveryMoment') }}
                <span v-if="weekDifference == 0">({{ $t('upcomingWarning', { day: $t(getSelectedDayName(state.date)) })
                }})</span>
                <span v-else-if="weekDifference == 1">({{ $t('nextWeekWarning', { day: $t(getSelectedDayName(state.date)) })
                }})</span>
                <span v-else>({{ $t('weekNumberDifference', { day: $t(getSelectedDayName(state.date)), days: weekDifference })
                }})</span>
            </p>
        </div>

        <VueDatePicker v-model="state.date" :enable-time-picker="false" week-numbers="iso" :format="format"
            :min-date="minDate()" :max-date="maxDate()" :disabled-week-days="[0]">
        </VueDatePicker>

        <div class="mt-2 mb-2" v-if="weekDifference == 0">
            <p>{{ $t('deliveryTimeLine') }}</p>
        </div>

        <button class="button button--primary mt-2" v-if="state.date" @click="$emit('confirmOrder', dateValue)">
            {{ $t('placeOrder') }}
        </button>
        <div v-if="orderExistsForSelectedDate(state.date)">
            <p>{{ $t('existingOrderWarning') }}</p>
        </div>
    </div>
</template>

<script>
import { reactive, computed, watch } from 'vue';

export default {
    name: "ProductOrderForm",
    props: {
        orders: {
            type: Array,
            required: false
        },
        placedOrderDates: {
            type: Array,
            required: false
        },
        orderConfirmed: {
            type: Boolean,
            required: true
        },
        weekNumber: {
            type: Number,
            nullable: true
        }
    },
    setup(props) {
        const state = reactive({
            date: new Date(),
        })

        const minDate = () => {
            let date = new Date()
            if (props.weekNumber !== getWeekNumber(date)) {
                date.setDate(date.getDate() + ((props.weekNumber - getWeekNumber(date)) * 7))
                date.setDate(date.getDate() - (date.getDay() - 1))
            } else {
                date.setDate(date.getDate() + 2)
                if (date.getHours() >= 14) {
                    date.setDate(date.getDate() + 1)
                }
            }

            return date
        }

        const maxDate = () => {
            let date = new Date()

            if (props.weekNumber !== getWeekNumber(date)) {
                date.setDate(date.getDate() + ((props.weekNumber - getWeekNumber(date)) * 7))
            }

            date.setDate(date.getDate() + (7 - date.getDay()))
            return date
        }

        const orderCount = computed(() => {
            let order_total = 0;
            props.orders.forEach(val => {
                order_total += Number(val.amount * val.amount_per_packaging);
                //or if you pass float numbers , use parseFloat()
            });
            return order_total;
        })

        const totalPrice = computed(() => {
            let order_total = 0;
            props.orders.forEach(val => {
                order_total += Number(val.price);
                //or if you pass float numbers , use parseFloat()
            });
            return order_total;
        })

        const dateValue = computed(() => {
            return state.date ? `${state.date.getFullYear()}-${state.date.getMonth() + 1}-${state.date.getDate()}` : null
        })

        const getWeekNumber = (d) => {
            d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
            // Set to nearest Thursday: current date + 4 - current day number
            // Make Sunday's day number 7
            d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
            // Get first day of year
            let yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
            // Calculate full weeks to nearest Thursday
            let weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
            // Return array of year and week number
            return weekNo;
        }

        state.date = minDate()

        const format = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;
        }

        const getSelectedDayName = (selectedDate) => {
            if (!selectedDate) return null;

            const options = { weekday: 'long' };
            return selectedDate.toLocaleDateString('en-US', options).toLowerCase();
        };

        const weekDifference = computed(() => {
            if (!state.date) return false;

            const selectedWeekNumber = getWeekNumber(state.date);
            const currentWeekNumber = getWeekNumber(new Date());
            console.log(selectedWeekNumber, currentWeekNumber)
            return selectedWeekNumber - currentWeekNumber;
        });

        // Your existing computed property for orderExistsForSelectedDate
        const orderExistsForSelectedDate = (selectedDate) => {
            if (!selectedDate.toLocaleDateString('nl-NL') || !props.placedOrderDates) return false;

            return Object.values(props.placedOrderDates).includes(selectedDate.toLocaleDateString('nl-NL'));
        };

        const toggleEdit = (order) => {
            if (order.editing) {
                // Save the changes
                order.editing = false;
            } else {
                // Start editing
                order.editing = true;
            }
        };

        const recalculatePrice = (order) => {
            order.price = (order.amount * order.amount_per_packaging) * order.price_per_packaging;
        };

        for (const order of props.orders) {
            watch(
                () => order.amount,
                (newAmount, oldAmount) => {
                    if (newAmount !== oldAmount) {
                        // Automatically increase the order amount to the minimal amount if it's lower
                        order.amount = Math.max(newAmount, order.minimal_amount);
                        recalculatePrice(order); // Recalculate the price after adjusting the amount
                    }
                }
            );
        }

        const adjustOrderAmount = (order) => {
            if (order.amount < order.minimal_amount) {
                order.amount = order.minimal_amount;
            }
        };

        const incrementOrderAmount = (order) => {
            order.amount++;
        };

        const decrementOrderAmount = (order) => {
            if (order.amount > order.minimal_amount) {
                order.amount--;
            }
        };

        return {
            state,
            orderCount,
            totalPrice,
            dateValue,
            props,
            weekDifference,
            toggleEdit,
            orderExistsForSelectedDate,
            minDate,
            maxDate,
            format,
            getSelectedDayName,
            recalculatePrice,
            adjustOrderAmount,
            incrementOrderAmount,
            decrementOrderAmount
        }
    }
}
</script>

<style scoped lang="scss">
.total-text {
    font-weight: 700;
}

table {
    width: 100%;
    margin-bottom: 2rem;
    font-size: 14px;
    border-collapse: collapse;
}

th,
td {
    border: 1px solid #dddddd;
    padding: 8px;
}

input {
    max-width: 50%;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary;
    color: $white;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
    margin: 0 3px;
}

.edit-section {
    display: flex;
    align-items: center;
}

.edit-button {
    width: 3rem;
    height: 3rem;
    max-width: 50%;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary;
    color: $white;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
    margin: 0 3px;
}

.edit-input {
    width: 5rem;
    height: 3rem;
    max-width: 50%;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary;
    color: $white;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
    margin: 0 3px;
}

.edit-controls {
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.edit-icon-button {
    font-size: 1rem;
    margin-right: 0.4rem;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.edit-icon {
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.3rem;
}

.confirmation-text {
    font-size: 14px;
}

.order-editing {
    background-color: lightgray;
}
</style>

<style>
.dp__input {
    font-size: 14px !important;
    line-height: 1.6rem !important;
    padding: 8px 12px !important;
    padding-left: 3rem !important;
}
</style>
