<template>
    <Modal v-if="user.accepted_term === null && accepted === false"
        :modalVisible="user.accepted_term === null && accepted === false">
        <div class="terms__content">
            <h2>Algemene Voorwaarden</h2>
            <p>Laatst bijgewerkt op: 20-09-2023</p>

            <p><strong>1. Toepasselijkheid</strong></p>
            <p>Deze algemene voorwaarden zijn van toepassing op alle aankopen via onze webshop. Door gebruik te maken van
                onze webshop en een bestelling te plaatsen, ga je akkoord met deze voorwaarden.</p>

            <p><strong>2. Bestellingen</strong></p>
            <p><strong>2.1.</strong> Je kunt bestellingen plaatsen via onze webshop. Wij behouden ons het recht voor om
                bestellingen te weigeren of te annuleren.</p>
            <p><strong>2.2.</strong> Prijzen, beschikbaarheid en verzendkosten zijn onderhevig aan verandering.</p>

            <p><strong>3. Betaling</strong></p>
            <p><strong>3.1.</strong> Betaling voor bestellingen wordt gedaan op het moment van vertrekken van de producten
                naar de klant, en dit gebeurt via een factuur die aan de klant wordt verstrekt.</p>

            <p><strong>4. Verzending en Levering</strong></p>
            <p><strong>4.1.</strong> Verzending gebeurt naar het opgegeven afleveradres nadat de betaling is ontvangen en
                geverifieerd.</p>
            <p><strong>4.2.</strong> De levertijd kan variëren en is afhankelijk van de beschikbaarheid van het product.</p>

            <!-- <p><strong>5. Geschillen</strong></p>
            <p><strong>5.1.</strong> Bij geschillen proberen we deze in onderling overleg op te lossen. Als dat niet
                mogelijk is, zijn de bevoegde rechtbanken in [jouw rechtsgebied] exclusief bevoegd.</p> -->

            <p><strong>6. Wijzigingen</strong></p>
            <p><strong>6.1.</strong> We behouden ons het recht voor om deze algemene voorwaarden op elk moment te wijzigen.
                Controleer regelmatig voor updates.</p>

            <p><strong>7. Betaaltermijn</strong></p>
            <p><strong>7.1.</strong> Bij het plaatsen van een bestelling via onze webshop ga je akkoord met de volgende
                betaaltermijn: betaling dient te worden voldaan op het moment van vertrekken van de producten naar de klant,
                via de verstrekte factuur.</p>
            <p><strong>7.2.</strong> Betaling dient te geschieden volgens de instructies vermeld in de factuur.</p>
            <p><strong>7.3.</strong> Het niet voldoen aan de betalingsinstructies kan leiden tot het opschorten van
                toekomstige bestellingen en het doorgeven van de openstaande schuld aan een incassobureau.</p>

            <p><strong>8. Samenstelling van Producten</strong></p>
            <p><strong>8.1.</strong> Wij streven ernaar om nauwkeurige en gedetailleerde informatie over de samenstelling
                van onze producten op onze webshop te verstrekken.</p>
            <p><strong>8.2.</strong> Het is belangrijk op te merken dat de samenstelling van de producten zoals vermeld op
                onze webshop als indicatief wordt beschouwd. De leverancier behoudt het recht om, om welke reden dan ook, af
                te wijken van de opgegeven samenstelling, mits de kwaliteit en waarde van het product behouden blijven.</p>
            <p><strong>8.3.</strong> In het geval van dergelijke afwijkingen zal dit geen invloed hebben op de kwaliteit of
                de waarde van het product dat aan jou wordt geleverd.</p>

            <p><strong>9. Controle van Aantallen</strong></p>
            <p><strong>9.1.</strong> Bij het plaatsen van een bestelling via onze webshop is het de verantwoordelijkheid van
                de klant om de aantallen en de specificaties van de bestelde producten nauwkeurig te controleren.</p>
            <p><strong>9.2.</strong> Na het indienen van een bestelling ontvangt de klant een bestelbevestiging per e-mail.
                Deze bestelbevestiging bevat de details van de bestelling, inclusief de aantallen en specificaties van de
                producten.</p>
            <p><strong>9.3.</strong> Wij raden ten zeerste aan dat de klant de bestelbevestiging zorgvuldig controleert om
                er zeker van te zijn dat deze overeenkomt met hun intentie. Eventuele discrepanties of fouten in de
                aantallen moeten onmiddellijk aan ons worden gemeld.</p>
            <p><strong>9.4.</strong> Wij zijn niet verantwoordelijk voor eventuele fouten of discrepanties in de aantallen
                van de bestelde producten als deze niet binnen een redelijke termijn na ontvangst van de bestelbevestiging
                aan ons worden gemeld.</p>
        </div>
        <div class="terms__action">
            <button class="button button--primary" @click="submitTerms">Accept</button>
        </div>
    </Modal>
</template>

<script>
import apiClient from '@services/apiClient'
import Modal from '../ui/Modal.vue'
import { inject, onMounted, reactive, ref, watch } from 'vue'

export default {
    name: "AcceptTerms",
    setup() {
        const user = inject('user')

        // const { t, locale } = useI18n()
        // const logo = ref(null);
        // const user = inject('user')
        // const localeValue = ref(user.locale)
        // const state = reactive({
        //     errorResponse: false,
        //     logo: null,
        // })
        const accepted = ref(false)
        // window.addEventListener('successResponse', function() {
        //     state.errorResponse = false
        // })

        // window.addEventListener('errorResponse', function() {
        //     state.errorResponse = true
        // })

        // const getSeasonLogo = () => {
        //     apiClient.get('/season-logo').then((resp) => {
        //         state.logo = resp.data
        //     });
        // }
        const submitTerms = () => {
            apiClient.post('/accept-terms').then((resp) => {
                accepted.value = true
            })
        }
        // watch(localeValue, function (val) {
        //     apiClient.post('/change-locale', {
        //         'locale': val
        //     }).then((resp) => {
        //         locale.value = resp.data.locale
        //     })
        // })

        // onMounted(() => {
        //     getSeasonLogo();
        // });

        return {
            user,
            submitTerms,
            accepted,
        }
    },
    components: {
        Modal
    }
}
</script>

<style lang="scss">
strong {
    font-weight: 900;
}

p {
    margin: 5px 0;
}

h2 {
    font-size: 2rem;
}

.terms {
    &__content {
        max-height: 70vh;
        padding: 0 2.5rem;
        overflow: hidden;
        overflow-y: auto;
    }

    &__action {
        margin-top: 2rem;
    }

}</style>
