<template>
    <div class="no-connection" v-if="state.errorResponse">
        <div class="wifi-icon">
            <font-awesome-icon icon="fa-solid fa-wifi-slash" />
        </div>
    </div>
    <header class="header">
        <div class="wrapper header__container">
            <div class="header__logo">
                <router-link to="/">
                    <img src="/images/hbm-logo.jpg" />
                    <img class="ml-5" v-if="state.logo && state.logo.seasonLogo" :src="state.logo.seasonLogo" />
                </router-link>
            </div>
            <div class="header__account">
                {{ user.name }}
                <a href="/logout" class="button button--primary-outline header__account-button">
                    <font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket" />
                </a>
                <select class="form__select form__select--locale" v-model="localeValue">
                    <option value="nl">NL</option>
                    <option value="de">DE</option>
                    <option value="en">EN</option>
                </select>
            </div>
            <Navbar />
        </div>
    </header>
</template>

<script>
import Navbar from './Navbar.vue';
import { inject, onMounted, reactive, ref, watch } from 'vue'
import apiClient from '@services/apiClient'
import { useI18n } from "vue-i18n";

export default {
    name: "Header",
    setup() {
        const { t, locale } = useI18n()
        const logo = ref(null);
        const user = inject('user')
        const localeValue = ref(user.locale)
        const state = reactive({
            errorResponse: false,
            logo: null,
        })

        window.addEventListener('successResponse', function () {
            state.errorResponse = false
        })

        window.addEventListener('errorResponse', function () {
            state.errorResponse = true
        })

        const getSeasonLogo = () => {
            apiClient.get('/season-logo').then((resp) => {
                state.logo = resp.data
            });
        }

        watch(localeValue, function (val) {
            apiClient.post('/change-locale', {
                'locale': val
            }).then((resp) => {
                locale.value = resp.data.locale
            })
        })

        onMounted(() => {
            getSeasonLogo();
        });

        return {
            user,
            state,
            logo,
            localeValue
        }
    },
    components: {
        Navbar
    },
}
</script>

<style lang="scss" scoped>
.no-connection {
    position: fixed;
    background-color: red;
    width: 100%;
    z-index: 999;
    min-height: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .wifi-icon {
        background-color: white;
        width: fit-content;
        height: fit-content;
        border-radius: 100%;
        padding: .5rem;

        svg {
            width: 2.5rem;
            height: 2.5rem;
        }
    }
}

.header {
    overflow-x: hidden;

    &__container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }

    &__logo {
        padding: 3rem;
        background-color: $primary;

        img {
            max-width: 12.5rem;
        }

        @media only screen and (max-width: 480px) {

            padding: 5rem 2rem;

            img {
                max-width: 6rem;
            }
        }
    }

    &__account {
        display: flex;
        align-items: center;
        gap: 1rem;
        font-size: 1.6rem;

        @media only screen and (max-width: 480px) {
            margin: 1rem 1rem;
            flex-direction: column;
            gap: 2rem;
            align-items: flex-start;
        }
    }

    &__account-button {
        padding: 0.82rem 1rem;
        font-size: 1.2rem;
    }
}
</style>
