import './bootstrap';
import {createApp} from "vue";
import {createPinia} from "pinia";
import {createI18n} from "vue-i18n";
import App from "./App.vue";
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import {createHead} from '@vueuse/head'
import apiClient from '@services/apiClient'
import '@flarone/media-library-pro-styles/dist/styles.css'
import VueEasyLightbox  from "vue-easy-lightbox";

//Icons
/* import the fontawesome core */
import {library} from '@fortawesome/fontawesome-svg-core'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

/* import font awesome icon component */
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

import {
    faXmark,
    faArrowRightFromBracket,
    faWifiSlash,
} from "@fortawesome/pro-solid-svg-icons";

library.add(faXmark, faArrowRightFromBracket, faWifiSlash);

/* Import the translation files, if you add another language, import the [lang].json file here */
import enTranslations from '@translations/en.json'
import nlTranslations from '@translations/nl.json'
import deTranslations from '@translations/de.json'

const messages = {
    en: enTranslations,
    de: deTranslations,
    nl: nlTranslations
}

const pinia = createPinia()
const app = createApp({});
const head = createHead({});

apiClient.get('/user').then((resp) => {
    const i18n = createI18n({
        legacy: false,
        locale: resp.data.locale,
        messages
    });

    app.provide('user', resp.data)
    app.component('app', App);
    app.component('font-awesome-icon', FontAwesomeIcon);
    app.component('VueDatePicker', VueDatePicker);
    app.use(router);
    app.use(VueEasyLightbox);
    app.use(head);
    app.use(pinia);
    app.use(VueAxios, axios);
    app.use(i18n);
    app.mount('#app');
})
