<template>
  <nav class="nav">

  </nav>
</template>

<script>
  export default {
    name: "Navbar",
    setup() {

    },
    components: {
    }
  }
</script>

<style lang="scss" scoped>
  .nav {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 0;
    width: 100%;
    height: 5rem;
    &::after {
      content: '';
      display: block;
      width: 100vw;
      height: 100%;
      position: absolute;
      top:0;
      left:50%;
      transform: translateX(-50%);
      background-color: $primary;
      z-index: -1;
    }

    &__link {
      color: $white;
      font-size: 1.6rem;
      font-weight: 400;
    }
  }
</style>
