<template>
    <div class="page-title">
        <div class="wrapper">
            <h1><slot></slot></h1>
        </div>
    </div>
</template>

<script>
import {reactive} from 'vue'

export default {
    name: 'PageTitle',
}
</script>

<style lang="scss" scoped>
.page-title {
    padding: 2rem 0;
    text-align: left;

    h1 {
        color: $primary;
        font-size: 3rem;
        font-weight: 700;
    }
}
</style>
